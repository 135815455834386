import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./Pages/App/Styles/App.css";
import "./Pages/App/Styles/Reusable.css";
import "./Pages/App/Styles/ReusableAnalysis.css";
import "./CompleteAccount.css";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";
import ErrorPage from "./Pages/Error/ErrorPage";
import SignInUp from "./Pages/SignIn/SignInUp";
import Redirecting from "./Pages/SignIn/SignInComponents/Redirecting";
import LoadingVisual from "./Pages/LoadingOptions/LoadingVisual";
import {  RouterProvider } from "react-router";
import { createBrowserRouter } from "react-router-dom";
import AccountNotPaid from "./Pages/SignIn/AccountNotPaid/AccountNotPaid";
const AppLoading = lazy(() => import("./Pages/App/AppLoading"));
const SetUpAccount = lazy(() => import("./Pages/SetUpAccount/SetUpAccount"));
const SetImage = lazy(() => import("./Pages/CompleteAccount/SetImage/SetImage"));
const CompanyDetails = lazy(() => import("./Pages/CompleteAccount/CompanyDetails/CompanyDetails"));
const CompanyLogo = lazy(() => import("./Pages/CompleteAccount/CompanyLogo/CompanyLogo"));
const SetUserDetails = lazy(() => import("./Pages/CompleteAccount/SetUserDetails/SetUserDetails"));

const router = createBrowserRouter([
  {
    path: "/",
    element: <SignInUp />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/app",
    element: (
      <Suspense fallback={<LoadingVisual/>}>
        <AppLoading />
      </Suspense>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/account-not-paid",
    element: (
      <Suspense fallback={<LoadingVisual/>}>
        <AccountNotPaid />
      </Suspense>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/set-user-details",
    element: (
      <Suspense fallback={<LoadingVisual/>}>
        <SetUserDetails />
      </Suspense>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/user-details-profile-image",
    element: (
      <Suspense fallback={<LoadingVisual/>}>
        <SetImage />
      </Suspense>
    ),
    errorElement: <ErrorPage />,
  },

  {
    path: "/account-set-up",
    element: (
      <Suspense fallback={<LoadingVisual/>}>
        <SetUpAccount />
      </Suspense>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/newCustomer",
    element: (
      <Suspense fallback={<LoadingVisual/>}>
        <Redirecting />
      </Suspense>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/company-details",
    element: (
      <Suspense fallback={<LoadingVisual/>}>
        <CompanyDetails />
      </Suspense>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/add-company-logo",
    element: (
      <Suspense fallback={<LoadingVisual/>}>
        <CompanyLogo />
      </Suspense>
    ),
    errorElement: <ErrorPage />,
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router}>
      <Suspense fallback={<LoadingVisual/>}>
        <RouterProvider router={router} />
      </Suspense>
    </RouterProvider>
  </React.StrictMode>
);
reportWebVitals();
serviceWorker.unregister();

