import './LoadingVisual.css'
const LoadingVisual = () => {
  return (
    <div className='loading'>
    <div className="loading__left">
      <div className="loading__left__top">
        <div className='dummy__container'>
        </div>
        <div className='dummy__container'>
        </div>
        <div className='dummy__container'>
        </div>
        <div className='dummy__container'>
        </div>
      </div>
      <div className='loading__left__bottom'>
        <div className="dummy__container">
        </div>
      </div>
    </div>

    <div className="loading__right"> 
    <div className='dummy__container' style={{minHeight: '20rem'}}>
    </div>
    <div className='loading__right__bottom'>
    <div className='dummy__container'></div>
    <div className='dummy__container'></div>
    <div className='dummy__container'></div>
   </div>
    </div>
    </div>
  )
}

export default LoadingVisual