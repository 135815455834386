import { useNavigate } from 'react-router-dom';
import { auth } from '../../App/Functions/firebase-config';
import './AccountNotPaid.css'

const AccountNotPaid = () => {
  const user = JSON.parse(sessionStorage.getItem('user'));
  const navigate = useNavigate()
  const signOut = async () => {
    try {
      document.documentElement.style.setProperty('--color-mode-main', '#FFFFFF');
      document.documentElement.style.setProperty('--color-mode-secondary', 'whitesmoke');
      document.documentElement.style.setProperty('--text-color', 'grey');
      document.documentElement.style.setProperty('--color-text', 'grey');
      await auth.signOut();
      navigate('/')
    } catch (err) {
      alert('Error signing out')
    }
  };
  return (
    <div className='new__company center__flex flex__column'>
      <div className="new__company__bg"></div>
      <div className="details__container">
        <h2>{user.fullName}, we couldn't collect your last payment</h2>
        <p className='span__hover'>To regain access, <a style={{textDecoration:' underline'}} href="https://billing.stripe.com/p/login/bIYeWd5St25bcI8144" target="_blank" rel="noreferrer">manage your account Here</a></p>
        <p className='span__hover'>Contact sales on sales@sitehubapp.com</p>
        <p className='span__hover' onClick={signOut}>Sign out</p>
      </div>
    </div>
  )
}

export default AccountNotPaid