import React, { useEffect } from 'react'
import SignIn from './SignInComponents/SignIn'
import SignUp from './SignInComponents/SignUp'
import './SignInUp.css'
import { useState } from 'react'
import {  useLocation, useNavigate } from 'react-router-dom'
import { useAuthState } from 'react-firebase-hooks/auth'
import { collection, doc, getDoc, setDoc, updateDoc, } from 'firebase/firestore'
import { auth, db } from '../App/Functions/firebase-config'
import ErrorAlert from '../App/Components/ErrorAlert/ErrorAlert'
import Loading from '../App/Components/Loading/Loading'
import ModalBg from '../App/Components/ModalBg/ModalBg'
import ResetPassword from './SignInComponents/ResetPassword/ResetPassword'
import Success from '../App/Components/ReUse/Success'
import { isUserPaid } from '../App/Functions/IsUserPaid'

const SignInUp = () => {
  const location = useLocation();
  const [isSignIn, setIsSignIn] = useState(true);
  const [foundEmail,setFoundEmail]=useState('')
  const [requestId, setRequestId] = useState(false);
  const [userFullName, setUserFullName] = useState('')
  const [referralIdIdUrl, setReferralIdIdUrl] = useState('')
  const [resetPassword, setResetPassword] = useState(false)
  const [success, setSuccess] = useState(false)
  const navigate = useNavigate()
  const [user, userLoading] = useAuthState(auth);
  const [loading, setLoading] = useState(false)
  const [message,setMessage]=useState('')
  const [planSelected, setPlanSelected] = useState(false)
  const [ setStripeConnected ] = useState(false)
  
  useEffect(() => {
    const currentURL = window.location.href;
    const urlSearchParams = new URLSearchParams(currentURL);
    const reqId = urlSearchParams.get('reqId');
    const user = urlSearchParams.get('user');
    const email = urlSearchParams.get('email');
    const referralId = urlSearchParams.get('referralId');
    const plan = urlSearchParams.get('plan');
    const billing = urlSearchParams.get('billing');
    const stripe__connected = urlSearchParams.get('stripe__connected');
    if(plan && billing) {
      setIsSignIn(false)
      setPlanSelected({
        plan,
        billing
      })
    }
    if(email){
      setFoundEmail(email)
    }
    if(reqId){
      // trigger function that gets reqId doc and checks if user is in
      setRequestId(reqId)
    }
    if(user){
      setUserFullName(user)
    }
    if(referralId){
      setReferralIdIdUrl(referralId)
    }
    if(stripe__connected === 'true'){
      setStripeConnected(true)
    }
  }, [location.search]);


  useEffect(()=>{
    if(user){
      setLoading('Locating your account')
      signInUser()
    }
  },[auth, user, userLoading])


  const signInUser = async () => {
    const uid = user.uid;
    const usersCollectionRef = collection(db, 'users');
    const userDocRef = doc(usersCollectionRef, uid);
    const userDocSnapshot = await getDoc(userDocRef);

    if(userDocSnapshot.exists()){ // if user is in firestore
      const users = userDocSnapshot.data()
      if(referralIdIdUrl){
        users.referralId = referralIdIdUrl
      }
      if(users.companyId){
      const company = doc(db, 'companies', users.companyId)
      const companySnapshot = await getDoc(company);
        if(companySnapshot.exists()){ // if company exists in database
          if(companySnapshot.data().isPaid){
            setLoading('Redirecting you')
            sessionStorage.setItem('user', JSON.stringify(userDocSnapshot.data()));
            const url = `/app`;
            navigate(url, { state: { account: users } });
          } else {
            if(users.isOwner){
              sessionStorage.setItem('user', JSON.stringify(users));
              sessionStorage.setItem('company', JSON.stringify(companySnapshot.data()));
              navigate('/account-not-paid', { state: { account: users } });
              setLoading(false)
            } else {
              setMessage("We couldn't sign you in, contact your manager for more information")
            }
          }
        } else { 
          users.companyId = false
          await updateDoc(userDocRef, {companyId : ''})
          sessionStorage.setItem('user', JSON.stringify(users));
          navigate('/account-set-up', { state: { account: users } });
          setLoading(false)
        } 
      }  else { // account but no company id
        // to join / set company
        if(requestId){
          const reqRef = doc(db, 'companyJoinRequests', requestId);
          const docSnap = await getDoc(reqRef);
          if (docSnap.exists()) {
            const docData = docSnap.data();
            users.companyRoles = docData.accountInfo
            users.companyId = docData.companyId
            sessionStorage.setItem('user', JSON.stringify(users));
            navigate('/set-user-details', { state: { account: users } });
          }
        } else {
          const isPaid = await isUserPaid()
          sessionStorage.setItem('user', JSON.stringify(users));
          if(isPaid){
            navigate('/company-details', { state: { account: users }});
          } else {
            navigate('/account-set-up', { state: { account: users }});
          }
          }
        }
    } else { // user auth but not on db (new user)
      const newUser = {email: user.email, fullName: user.displayName ? user.displayName : '', id: user.uid, submittedJoinRequest: false}
      if(referralIdIdUrl){
        newUser.referralId = referralIdIdUrl
      }

      if(userFullName){
        newUser.fullName = userFullName
      }
      const docRef = doc(db, 'users', user.uid)
      await setDoc(docRef, newUser);
      sessionStorage.setItem('user', JSON.stringify(newUser));
      sessionStorage.setItem('plan', JSON.stringify(planSelected));
      navigate('/set-user-details', { state: { account: newUser } });
    }
  }

  return (
    <>

      {message && (
        <ErrorAlert message={message} setMessage={setMessage}/>
      )}

      {success && (
        <Success success={success} setSuccess={setSuccess} />
      )}
      {loading && (
        <div className='redirecting-page gradient__bg'>
          <Loading message={loading}/>
        </div>
      )} 

      {resetPassword && (
        <ModalBg open={resetPassword} closePopUp={()=>{setResetPassword(false)}}>
          <ResetPassword setSuccess={setSuccess} setResetPassword={setResetPassword}/>
        </ModalBg>
      )}

      <div className='new__company'>
        <div className="new__company__bg"></div>
        <div className="details__container">
          <div className='flex__column gap1'>
            <div className="flex__column">
              <h2>siteHUB</h2>
              <p>{isSignIn ? 'Please sign in to continue' : 'Enter your details to sign up'}</p>
            </div>
              {isSignIn ?
                <SignIn setIsSignIn={setIsSignIn}setMessage={setMessage} setLoading={setLoading}/>
                :
                <SignUp dbase={'users'} setIsSignIn={setIsSignIn} setLoading={setLoading} foundEmail={foundEmail} userFullName={userFullName} />
              }
            <div className="flex__end flex__column">
              {!isSignIn && (
                <p>By signing up you agree to our 
                  <a style={{textDecoration: 'underline', marginLeft: '0.5rem'}} className='span__hover' href="/privacy" target="_blank" rel="noopener noreferrer">
                    <span>terms and conditions</span>
                  </a>
                </p>
              )}
              <p>{isSignIn ? 'Set up your account' : 'Already have an account?'} <span style={{textDecoration: 'underline'}} className='span__hover' onClick={()=>setIsSignIn(!isSignIn)}>Sign {!isSignIn ? 'in' : 'up'}</span></p>
              <p>Forgotten password? <span style={{textDecoration: 'underline'}} className='span__hover' onClick={()=>setResetPassword(true)}>Reset</span></p>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default SignInUp



//scenarios

  // user wants company fresh sign up
  // user returning mid sign up
  // user wants to join company
  // user invited to join company
