import { doc, getDoc } from 'firebase/firestore';
import { useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../../App/Functions/firebase-config';
import { isUserPaid } from '../../App/Functions/IsUserPaid';
import LoadingVisual from '../../LoadingOptions/LoadingVisual';


const Redirecting = () => {
  const navigate = useNavigate()
  const uid = window.location.hash.substring(1);
  const [user, userLoading] = useAuthState(auth);

  useEffect(()=>{
    const signInUser = async () => {
      try {
        const isPaid = await isUserPaid();
        if (isPaid) {
          const userDocRef = doc(db, 'users', uid);
          const userDocSnapshot = await getDoc(userDocRef);
          if (userDocSnapshot.exists()) {
            const users = userDocSnapshot.data();
            let url = `/company-details`;
            sessionStorage.setItem('user', JSON.stringify(userDocSnapshot.data()));
            navigate(url, { state: { account: users} });
          }
        } else {
          console.log('non');
          // signOut()
        }
      } catch (error) {
        console.error('Error signing in user:', error);
      }
    }  
    signInUser()
  }, [ user, userLoading, uid, navigate ]);

  return (
    <LoadingVisual />
  )
}

export default Redirecting